import React, { useEffect, useState } from "react";
import Customers from "./customers/Customers";
import Releases from "./releases/Releases";
import Log from "./log/Log";
import Users from "./users/Users";
import Roles from "./roles/Roles";
import SideNav from "../shared/side-nav/SideNav";
import Header from "../shared/header/Header";
import CustomerProfile from "./profile/CustomerProfile";
import CustomerLicenses from "./licenses/CustomerLicenses";
import RequestQuotation from "./quotation/RequestQuotation";
import { axiosIntance, protectedAxiosIntance } from "../API/Base";
import { useDispatch, useSelector } from "react-redux";
import { Logout, setProfile } from "../../actions/Profile";
import { setSidebarContent } from "../../actions/Sidebar";
import { useHistory } from "react-router-dom";
import { message, Spin } from "antd";

const Container = (props) => {
  message.config({ maxCount: 1 });
  const [view, setView] = useState(6);
  const customer = useSelector((state) => state.profile.user.customer);
  const token = useSelector((state) => state.profile.token);
  const recent = useSelector((state) => state.sidebar.num);
  const dispatch = useDispatch();
  const history = useHistory();
  const [content, setContent] = useState(<Spin tip="Loading..." size="large" />);

  const tokenExpired = () => {
    message.warning("Session Expired");
    axiosIntance
      .post(`/customers/logout`, null, { params: { token } })
      .then((res) => {
        if (res.data.statusCode === "OK") {
          console.log("logout Done");
        } else {
          console.log("logout error");
        }
      })
      .catch((err) => console.log(err));
    history.push("/");
    document.cookie = "token=;";
    localStorage.clear();
    dispatch(Logout());
    dispatch(setSidebarContent(6));
  };

  //reloading
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await protectedAxiosIntance().get(`customers/validate-authentication?timestamp=${new Date().getTime()}`);
        if (response.data === "authentication is valid!" || response.status === 200) {
          if (window.performance) {
            if (String(window.performance.getEntriesByType("navigation")[0].type) === "reload") {
              const customerData = await protectedAxiosIntance().post("customers/getCustomerData", {
                id: customer.id,
                email: customer.email,
              });
              dispatch(setProfile(customerData.data.data));
              setView(recent);
            }
          }
        } else {
          tokenExpired();
        }
      } catch (err) {
        if (err.response && err.response.status === 410) {
          tokenExpired();
        } else {
          console.log(err);
        }
      }
    };

    fetchData();
  }, []);

  const handleClick = async (num) => {
    if (num === 6 || num === 7) {
      try {
        const customerData = await protectedAxiosIntance().post("customers/getCustomerData", {
          id: customer.id,
          email: customer.email,
        });
        dispatch(setProfile(customerData.data.data));
      } catch (err) {
        console.log(err);
      }
    }
    dispatch(setSidebarContent(num));
    setView(num);
  };

  //changing view
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await protectedAxiosIntance().get(`customers/validate-authentication?timestamp=${new Date().getTime()}`);
        console.log(response);
        if (response.data === "authentication is valid!" || response.status === 200) {
          switch (view) {
            case 1:
              setContent(<Customers isCustomer={props.isCustomer} />);
              break;
            case 2:
              setContent(<Releases />);
              break;
            case 3:
              setContent(<Log />);
              break;
            case 4:
              setContent(<Users />);
              break;
            case 5:
              setContent(<Roles />);
              break;
            case 6:
              setContent(<CustomerProfile isCustomer={props.isCustomer} />);
              break;
            case 7:
              setContent(<CustomerLicenses />);
              break;
            case 8:
              setContent(<RequestQuotation />);
              break;
            default:
              break;
          }
        } else {
          tokenExpired();
        }
      } catch (err) {
        tokenExpired();
      }
    };

    fetchData();
  }, [view]);

  return (
    <div style={{ display: "flex" }}>
      <SideNav handleClick={handleClick} view={view} isCustomer={props.isCustomer} />
      <div style={{ width: "100%", padding: "0 2rem" }}>
        <Header handleLogin={props.handleLogin} />
        <div style={{ height: "90vh", overflowY: "scroll" }}>{content}</div>
      </div>
    </div>
  );
};

export default Container;
